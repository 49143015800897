* {
  box-sizing: border-box;
}
html,
body {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}