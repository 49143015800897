
.logo {
  margin-left: 20px;
  cursor: pointer;
}

.removePadding {
  padding: 0 !important;
}

.navbar-toggler {
  margin-right: 10px;
}
.gif-logo {
  height: 48px;
  width: 48px;
}
.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}